body, html {
  margin: 0;
  font-family: 'Vazir', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-list::-webkit-scrollbar {
  width: 5px;
  
}

/* Track */
.card-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.card-list::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
  opacity: 0.5;
}

/* Handle on hover */
.card-list::-webkit-scrollbar-thumb:hover {
  background: var(--mid-dark);
}

code {
  font-family: 'Vazir', sans-serif;
}
